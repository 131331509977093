/**
 * Image Modal
 * @description :
 */

(function () {

    document.querySelectorAll('.js-image-modal-container').forEach(function (item) {
        var toggleImage = item.querySelector('.js-image-modal-open');
        var imageModal = item.querySelector('.js-image-modal');

        toggleImage.addEventListener('click', function() {
            imageModal.classList.add('c-image-modal--open');
        })
    });

})();
